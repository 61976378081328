// @import url('https://use.typekit.net/csq7kbl.css');

$font-family-heading: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-copy: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

//fdsjfkhfasdjkflsah

body {
  font-family: $font-family-copy;
  color: #000;
  // background-image: url('./assets/images/Hintergrund_GIF_1.gif'), url('./assets/images/Hintergrund_GIF_2.gif'),
  //   url('./assets/images/Hintergrund_GIF_3.gif'), url('./assets/images/Hintergrund_GIF_4.gif');

  // // background-image:
  // //   url('https://via.placeholder.com/500/f00'),
  // //   url('https://via.placeholder.com/500/0f0'),
  // //   url('https://via.placeholder.com/500/00f'),
  // //   url('https://via.placeholder.com/500/ff0');
  // background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  // background-position: top left, top right, bottom left, bottom right;
  // background-attachment: fixed;
  // background-size: 50% 50%;
}
.product-content {
  background: transparent;
}

#root {
  padding-top: 25px;
  @include media-breakpoint-up(md) {
    padding-top: 100px;
  }
}

h1,
h2,
.btn,
legend,
.modal-title,
#footer h5,
.loading-spinner-content {
  font-family: $font-family-heading;
  // text-transform: uppercase;
}

// NAV

.navbar-light {
  // background-color: rgba($color: $white, $alpha: 0.9) !important;
  background-color: $white !important;
}

.navbar .hamburger {
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.offcanvas-collapse {
  top: 8rem;
  @include media-breakpoint-down(sm) {
    top: 4rem;
  }
  //background-color: $gray-900;
  //box-shadow: inset 0 20px 15px -20px rgba(0,0,0,.15);
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: $black;
    // background-repeat: no-repeat;
    // background-position: top left;
    // background-image: url('./assets/images/Bloeck_1.svg');

    // &:hover,
    // &:focus {
    //   background-image: url('./assets/images/Bloeck_4.svg');
    // }

    // &:nth-child(2n) {
    //   background-image: url('./assets/images/Bloeck_2.svg');
    //   &:hover,
    //   &:focus {
    //     background-image: url('./assets/images/Bloeck_5.svg');
    //   }
    // }
    // &:nth-child(3n) {
    //   background-image: url('./assets/images/Bloeck_3.svg');
    //   &:hover,
    //   &:focus {
    //     background-image: url('./assets/images/Bloeck_6.svg');
    //   }
    // }
    // &:nth-child(4n) {
    //   background-image: url('./assets/images/Bloeck_4.svg');
    //   &:hover,
    //   &:focus {
    //     background-image: url('./assets/images/Bloeck_1.svg');
    //   }
    // }
    // &:nth-child(5n) {
    //   background-image: url('./assets/images/Bloeck_5.svg');
    //   &:hover,
    //   &:focus {
    //     background-image: url('./assets/images/Bloeck_2.svg');
    //   }
    // }
    // &:nth-child(6n) {
    //   background-image: url('./assets/images/Bloeck_6.svg');
    //   &:hover,
    //   &:focus {
    //     background-image: url('./assets/images/Bloeck_3.svg');
    //   }
    // }

    .nav-link {
      color: $white;
      display: block;
      width: 100%;
    }
    .nav-link.active,
    .nav-link:hover {
      color: $gray-500;
      font-style: italic;
    }
    font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: $white, $alpha: 0.975) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

// MAKI

.maki {
  .card-title {
    font-family: $font-family-heading;
    text-align: center;
    span.category {
      font-family: $font-family-copy;
    }
  }
}

.close,
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 1;
}

.scribble-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  @include media-breakpoint-up(md) {
    width: 30px;
    height: 30px;
  }
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain !important;

  &.scribble-icon-close {
    background-image: url('./assets/images/Icon_Kreuz_1.png');
    a:hover &,
    button:hover &,
    a:focus &,
    button:focus & {
      background-image: url('./assets/images/Icon_Kreuz_1.png');
    }
  }

  &.scribble-icon-cart {
    background-image: url('./assets/images/Icon_Warenkorb_1.png');
    a:hover &,
    button:hover &,
    a:focus &,
    button:focus & {
      background-image: url('./assets/images/Icon_Warenkorb_1.png');
    }
  }

  &.scribble-icon-customer {
    background-image: url('./assets/images/Icon_Account_1.png');
    a:hover &,
    button:hover &,
    a:focus &,
    button:focus & {
      background-image: url('./assets/images/Icon_Account_1.png');
    }
  }

  &.scribble-icon-burger-menu {
    background-image: url('./assets/images/Icon_Menue_1.png');
    a:hover &,
    button:hover &,
    a:focus &,
    button:focus & {
      background-image: url('./assets/images/Icon_Menue_1.png');
    }

    a.is-active &,
    button.is-active & {
      background-image: url('./assets/images/Icon_Kreuz_1.png');
    }
    a.is-active:hover &,
    button.is-active:hover & {
      background-image: url('./assets/images/Icon_Kreuz_1.png');
    }
  }

  &.scribble-icon-logo {
    width: 250px;
    height: 75px;
    max-width: 100%;
    @include media-breakpoint-down(sm) {
      width: 100px;
      height: 40px;
    }
    background-image: url('./assets/images/Logo.png');
    background-size: contain;
    // :hover > & {
    //   background-image: url('./assets/images/Logo.svg');
    // }
  }
}

.maki-container .maki.card:hover .maki-img {
  opacity: 1;
}

// PDP

.detail-page {
  h1 {
    margin-top: 0;
  }
  .product-title {
    text-align: left;
  }
}
.carousel.carousel-scoll-snap {
  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 40px;
    height: 40px;
    background-image: url('./assets/images/Icon_links_1.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    &::before {
      display: none;
    }
  }
  .carousel-control-next-icon {
    background-image: url('./assets/images/Icon_rechts_1.png');
    &::before {
      display: none;
    }
  }
  .carousel-control-prev:hover .carousel-control-prev-icon {
    background-image: url('./assets/images/Icon_links_1.png');
  }
  .carousel-control-next:hover .carousel-control-next-icon {
    background-image: url('./assets/images/Icon_rechts_1.png');
  }
}
.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: transparent;
}

.blurry-background-image:before {
  background-image: none;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      margin: 0.3rem !important;
    }
  }
}

.product-thumb {
  box-shadow: none;
  //border: 1px solid $dark;
}
.radio-list-group .product-thumb {
  border-radius: 0;
  box-shadow: 0 0 0 1px $dark;
  border: 7px solid $white;
  background: $white;
}

// PDP MOBILE

// FOOTER

#footer {
  //background-color: $gray-200;
  color: $primary;
  //border-bottom: 10px solid $primary;
}

#product-listing {
  padding-top: 2rem;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

.variant-chooser {
  .variant-chooser-price {
    font-weight: normal;
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// TICKETS

#ticket-listing,
.ticket-listing {
  .list-page {
    //padding-top: 8vh;

    // padding-left: 15vw;
    // padding-right: 15vw;
    // @include media-breakpoint-down(md) {
    //   padding-left: 8vw;
    //   padding-right: 8vw;
    // }
  }

  .nigiri.card {
    background-color: transparent;
    border: 1px solid $bgcolor;
    border-radius: 2px;

    //border-bottom: 1px solid rgba($white, 0.25);
    .nigiri-content {
      padding: 1.25rem;
      //margin-top: 0.2rem;
      color: $black;
      @include media-breakpoint-up(md) {
        align-items: center;
      }
      @include media-breakpoint-down(md) {
        display: table;
      }
    }
    .nigiri-date {
      font-size: $h3-font-size;
      color: $primary;
      align-items: center !important;
      @include media-breakpoint-down(md) {
        font-size: $h5-font-size;
      }
    }
    .nigiri-body {
      font-size: $h3-font-size;
      border-left: 0;
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 3rem;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        line-height: 2rem;
      }
    }
    .badge {
      z-index: 2;
      font-size: 0.8em;
    }
    .title {
      font-size: $h3-font-size;
      margin-bottom: 0;
      color: $primary;
      font-family: $font-family-sans-serif !important;
    }
    .subtitle {
      font-size: $font-size-sm;
      margin-bottom: 0;
      color: $gray-600;
      font-weight: normal;
      text-transform: none;
      @include media-breakpoint-down(md) {
        line-height: 1rem;
        margin-bottom: 0.5rem;
      }
    }
    .location {
      font-weight: 600 !important;
      font-size: $h2-font-size !important;
      text-transform: none;
      color: $primary;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }
    &:hover {
      background-color: lighten($bgcolor, 1%);
    }
  }
}

.ticket-detail-page {
  .blurry-background-image {
    //height: 50vh;
    //background-color: $gray-800;
  }

  .blurry-background-image:before {
    filter: blur(1.5rem);
    opacity: 0.8;
    background-position: center center;
  }

  // .blurry-background-image:before {
  //   background-image: none;
  // }
  .carousel-inner {
    //display: none !important;
  }

  .article-title {
    padding-left: 0;
    padding-right: 0;
    background: transparent !important;
    border: 0;
    border-bottom: 0.1rem solid rgba($black, 0.08);

    .super-title {
      font-size: 0.7rem;
      opacity: 1;
    }
  }

  .product-info-bar {
    background-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.1rem solid rgba($black, 0.08);
    box-shadow: none;
    i.far {
      color: $secondary !important;
    }
    .navbar-text {
      text-align: left;
      margin-left: 0;
      display: block;
    }
  }
  .variant-listing {
    .visible,
    .invisible {
      display: none !important;
    }
  }
}
